import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';

import {useAuth} from 'hooks/useAuth';
import {useAuthStore} from 'stores/authStore';
import {adminPaths, authPaths, paths} from 'routes/path';
import {useAdminPermissionSettings} from 'hooks/datahook/adminPermission';
import {Center, Spinner} from '@chakra-ui/react';
import {get, last, split, trim} from 'lodash';
import {useNavigate, useMatches} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Stack, Box, Text, HStack} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import {find} from 'lodash';
import DateSelector from 'components/DateSelector';
import Selector from 'components/Selector';
import {sourceList} from 'page/app/ConstantGroup';
import {addMonths} from 'date-fns';
import {cleanObjectNilValue} from 'utils/clean';
import {formatMonthDataAndYear, currencyFormatorWithDecimal} from 'utils/formator';
import {useTotalUnrealisedPnl} from 'hooks/datahook/managementReport'
// ----------------------------------------------------------------------

export default function ManagementLayout() {
    const {t} = useTranslation('app');
    const [year, setYear] = useState(new Date());
    const [startDate, setStartDate] = useState(addMonths(new Date(), -1));
    const [endDate, setEndDate] = useState(new Date());
    const [source, setSource] = useState(null);
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const { data, isLoading } = useTotalUnrealisedPnl({ startDate, endDate, year: year.getFullYear(), source })

    useEffect(() => {
        navigate(pathname, {
            state: cleanObjectNilValue({
                startDate: startDate
                    ? formatMonthDataAndYear(startDate.toISOString())
                    : null,
                endDate: endDate
                    ? formatMonthDataAndYear(endDate.toISOString())
                    : null,
                year: year?.getFullYear(),
                source,
            }),
        });
    }, [startDate, endDate, year, source]);

    return (
        <Stack spacing={4}>
            <HStack spacing={4} alignItems="center">
                <HStack spacing={4} w={700} alignItems="center">
                    <Stack textAlign={'center'} spacing={0} flex={1} mb={-2}>
                        <Text fontSize={'sm'}>
                            {t('closedPnlSelectedDates')}
                        </Text>
                        <Text fontSize={21} color={get(data, 'selectClosedPnl')>1?"#10B02B": "#FF0505"}>
                            {currencyFormatorWithDecimal(get(data, 'selectClosedPnl')/1000, '')+`${get(data, 'selectClosedPnl')?' K':''}`}
                        </Text>
                    </Stack>
                    <Stack textAlign={'center'} spacing={0} flex={1} mb={-2}>
                        <Text fontSize={'sm'}>
                            {t('closedPnlCurrentMonth')}
                        </Text>
                        <Text fontSize={21} color={get(data, 'currentMonthClosedPnl')>1?"#10B02B": "#FF0505"}>
                            {currencyFormatorWithDecimal(get(data, 'currentMonthClosedPnl')/1000, '')+`${get(data, 'currentMonthClosedPnl')?' K':''}`}
                        </Text>
                    </Stack>
                    <Stack textAlign={'center'} spacing={0} flex={1} mb={-2}>
                        <Text fontSize={'sm'}>{t('closedPnlYearToDate')}</Text>
                        <Text fontSize={21} color={get(data, 'yearClosedPnl')>1?"#10B02B": "#FF0505"}>
                            {currencyFormatorWithDecimal(get(data, 'yearClosedPnl')/1000, '')+`${get(data, 'yearClosedPnl')?' K':''}`}
                        </Text>
                    </Stack>
                </HStack>
                <Stack>
                    <HStack spacing={4}>
                        <HStack flex={1}>
                            <Text fontSize={'xs'}>{t('year')}: </Text>
                            <DateSelector
                                value={year}
                                onChangeValue={setYear}
                                granularity={'year'}
                                yearMode
                            />
                        </HStack>
                        <HStack flex={2}>
                            <Text fontSize={'xs'} w={69}>
                                {t('dateRange')}:
                            </Text>
                            <DateSelector
                                value={startDate}
                                onChangeValue={setStartDate}
                                granularity={'day'}
                                maxDate={new Date()}
                                placeholder={t('startDate')}
                            />
                            <DateSelector
                                value={endDate}
                                onChangeValue={setEndDate}
                                granularity={'day'}
                                maxDate={new Date()}
                                placeholder={t('endDate')}
                            />
                        </HStack>
                        <HStack flex={1}>
                            <Text fontSize={'xs'}>{t('source')}:</Text>
                            <Selector
                                options={sourceList}
                                value={source}
                                onChangeValue={setSource}
                                allowClear
                            />
                        </HStack>
                        <HStack flex={1}>
                            <Text fontSize={'xs'}>{t('bookType')}:</Text>
                            <Selector options={[]} />
                        </HStack>
                    </HStack>
                    <HStack w={'100%'} spacing={4}>
                        <Box display={'flex'} flexDirection={'row'}>
                            <Text fontSize={'xs'} mr={1}>
                                {t('totalClosedLots')}:{' '}
                            </Text>
                            <Text
                                fontSize={'xs'}
                                fontWeight={'bold'}
                                color={'#10B02B'}
                            >
                                {currencyFormatorWithDecimal(get(data, 'lotsData.Total', 0), '')}
                            </Text>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'}>
                            <Text fontSize={'xs'} mr={1}>
                                {t('MT4s1')}:{' '}
                            </Text>
                            <Text
                                fontSize={'xs'}
                                fontWeight={'bold'}
                                color={'#10B02B'}
                            >
                                {currencyFormatorWithDecimal(get(data, 'lotsData.MT4s1', 0), '')}
                            </Text>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'}>
                            <Text fontSize={'xs'} mr={1}>
                                {t('MT4s2')}:{' '}
                            </Text>
                            <Text
                                fontSize={'xs'}
                                fontWeight={'bold'}
                                color={'#10B02B'}
                            >
                                {currencyFormatorWithDecimal(get(data, 'lotsData.MT4s2', 0), '')}
                            </Text>
                        </Box>{' '}
                        <Box display={'flex'} flexDirection={'row'}>
                            <Text fontSize={'xs'} mr={1}>
                                {t('MT4s3')}:{' '}
                            </Text>
                            <Text
                                fontSize={'xs'}
                                fontWeight={'bold'}
                                color={'#10B02B'}
                            >
                                {currencyFormatorWithDecimal(get(data, 'lotsData.MT4s3', 0), '')}
                            </Text>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'}>
                            <Text fontSize={'xs'} mr={1}>
                                {t('MT5')}:{' '}
                            </Text>
                            <Text
                                fontSize={'xs'}
                                fontWeight={'bold'}
                                color={'#10B02B'}
                            >
                                {currencyFormatorWithDecimal(get(data, 'lotsData.MT5', 0), '')}
                            </Text>
                        </Box>
                    </HStack>
                </Stack>
            </HStack>
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
}
