import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import {api} from './common';
import {cleanObjectNilValue} from '../../utils/clean';
import {get} from 'lodash';
import {processPlatformAccount} from '../../data/platformAccount';
import {useAuth} from 'hooks/useAuth';
import {addDays, addMonths, format, parseISO} from 'date-fns';
import {formatMonthDataAndYear} from 'utils/formator';
import { processTotalTrades } from 'data/managementReport'

export const useVolumeAnalysis = ({ startDate, endDate, source }) => {
    const swr = useSWRTemplate({
        key: ['volume-analysis', startDate, endDate, source],
        request: async ([_, _startDate, _endDate, _source]) => {
            const {result} = await api().get(['mtreport', 'lot', 'close'], cleanObjectNilValue({ startDate: _startDate, endDate: _endDate, source: _source }))
            return result
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useVolumeAnalysisByYear = ({ year, source }) => {
    const swr = useSWRTemplate({
        key: ['volume-analysis-by-year', year, source],
        request: async ([_, _year, _source]) => {
            const {result} = await api().get(['mtreport', 'lot', 'year-vol'], cleanObjectNilValue({ year: _year, source: _source }))
            return result.map(item=>{
                console.log(new Date(get(item, 'mtDate')))
                return {
                    ...item,
                    date: format(parseISO(new Date(get(item, 'mtDate')).toISOString()), 'PP').split(
                        ' ',
                    ),
                }
            })
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useVolumeAnalysisBySymbol = ({ startDate, endDate, source }) => {
    const swr = useSWRTemplate({
        key: ['volume-analysis-by-symbol', startDate, endDate, source],
        request: async ([_, _startDate, _endDate, _source]) => {
            const {result} = await api().get(['mtreport', 'lot', 'symbols'], cleanObjectNilValue({ startDate: _startDate, endDate: _endDate, source: 'MT4s1' }))
            return result
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useAccountAnalysis = () => {
    const swr = useSWRTemplate({
        key: ['account-analysis'],
        request: async () => {
            return new Array(30).fill(0).map((item, index) => {
                const s1 = Math.random() * 100000;
                const s2 = Math.random() * 100000;
                const s3 = Math.random() * 100000;
                const mt5 = Math.random() * 100000;
                return {
                    mt4s1: s1.toFixed(2),
                    mt4s2: s2.toFixed(2),
                    mt4s3: s3.toFixed(2),
                    mt5: mt5.toFixed(2),
                    total: (s1 + s2 + s3 + mt5).toFixed(2),
                    date: formatMonthDataAndYear(
                        addDays(new Date(), 0 - index).toISOString(),
                    ),
                };
            });
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useAccountAnalysisByYear = () => {
    const swr = useSWRTemplate({
        key: ['volume-analysis-by-year'],
        request: async () => {
            return new Array(12).fill(0).map((item, index) => {
                const s1 = Math.random() * 100000;
                const s2 = Math.random() * 100000;
                const s3 = Math.random() * 100000;
                const mt5 = Math.random() * 100000;
                return {
                    mt4s1: s1.toFixed(2),
                    mt4s2: s2.toFixed(2),
                    mt4s3: s3.toFixed(2),
                    mt5: mt5.toFixed(2),
                    total: (s1 + s2 + s3 + mt5).toFixed(2),
                    date: format(addMonths(new Date(), 0 - index), 'PP').split(
                        ' ',
                    ),
                };
            });
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useAccountAnalysisByCountry = () => {
    const swr = useSWRTemplate({
        key: ['account-analysis-by-country'],
        request: async () => {
            return [
                'AF',
                'AX',
                'AL',
                'DZ',
                'AS',
                'AD',
                'AO',
                'AI',
                'AQ',
                'AG',
                'AR',
                'AM',
                'AW',
                'AU',
                'AT',
                'AZ',
                'BS',
                'BH',
                'BD',
                'BB',
                'BY',
                'BE',
                'BZ',
                'BJ',
                'BM',
                'BT',
                'BO',
                'BA',
                'BW',
                'BV',
                'BR',
                'IO',
                'VG',
                'BN',
                'BG',
                'BF',
                'BI',
                'KH',
                'CM',
                'CA',
                'CV',
                'BQ',
                'KY',
                'CF',
                'TD',
            ].map((item, index) => {
                const s1 = Math.random() * 100;
                return {
                    name: item,
                    value: s1,
                };
            }).sort((a, b)=>{
                return b.value - a.value
            });
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};


export const useAccountAnalysisByTop = () => {
    const swr = useSWRTemplate({
        key: ['volume-analysis-by-top'],
        request: async () => {
            return new Array(9).fill(0).map((item, index) => {
                return {
                    source: 'MT4s1',
                    login: 7000233,
                    group: 'U-FOX-S-TEST',
                    leverage: '1:500',
                    lots: 34,
                    avgLots: 3,
                    totalTrades: 13443
                };
            });
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};


export const useClosedAnalysisByDates = (options={}) => {
    let { startDate, endDate, source } = options
    source === "MT4" && (source = "MT4s1")
    const swr = useSWRTemplate({
        key: ['closed-analysis-by-dates', startDate, endDate, source],
        request: async ([_, _startDate, _endDate, _source]) => {
            const {result} = await api().get(['mtreport', 'pnl', 'data'], cleanObjectNilValue({
                startDate: _startDate? formatMonthDataAndYear(_startDate): null,
                endDate: _endDate? formatMonthDataAndYear(_endDate): null,
                source: _source,
            }))
            return result.map(processTotalTrades)
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisByYear = (options={}) => {
    let { year, source } = options
    source === "MT4" && (source = "MT4s1")
    const swr = useSWRTemplate({
        key: year? ['closed-analysis-by-year', year, source] : null,
        request: async ([_, _year, _source]) => {
            const {result} = await api().get(['mtreport', 'pnl', 'year-close'], cleanObjectNilValue({
                year: _year,
                source: _source
            }))
            return result.map(processTotalTrades)
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisByServers = (options={}) => {
    let { startDate, endDate, source } = options
    source === "MT4" && (source = "MT4s1")
    const swr = useSWRTemplate({
        key: ['closed-analysis-by-servers', startDate, endDate, source],
        request: async ([_, _startDate, _endDate, _source]) => {
            const {result} = await api().get(['mtreport', 'pnl', 'close'], cleanObjectNilValue({
                startDate: _startDate? formatMonthDataAndYear(_startDate): null,
                endDate: _endDate? formatMonthDataAndYear(_endDate): null,
                source: _source,
            }))
            return result.map(processTotalTrades)
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisByEquity = (options={}) => {
    let { year, source } = options
    source === "MT4" && (source = "MT4s1")
    const swr = useSWRTemplate({
        key: ['closed-analysis-by-equity', year, source],
        request: async ([_, _year, _source]) => {
            const {result} = await api().get(['mtreport', 'pnl', 'year-equity'], cleanObjectNilValue({
                year: _year,
                source: _source
            }))
            return result.map(processTotalTrades)
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};


export const useTotalUnrealisedPnl = (options={}) => {
    const { startDate, endDate, source, year } = options
    const swr = useSWRTemplate({
        key: ['total-unrealised-pnl', startDate, endDate, source, year],
        request: async ([_, _startDate, _endDate, _source, _year]) => {
            const {result} = await api().get(['mtreport', 'total'], cleanObjectNilValue({
                source: _source,
                startDate: _startDate? formatMonthDataAndYear(_startDate): null,
                endDate: _endDate? formatMonthDataAndYear(_endDate): null,
                year: _year
            }))
            return result
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};